import React from "react";
import Header from "../../components/Header";
// import Developers from "../Developers";

function Home() {
  return (
    <div>
      <div className="container">
        <Header />
        {/* <Developers /> */}
      </div>
    </div>
  );
}

export default Home;
